<template>
  <div>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-shipping-fast </v-icon>
        <v-toolbar-title class="pl-4">
          Configurações de Transportadoras:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="(dialogAddTransportadora = true, limparItemSelecionado())"> Adicionar </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.objetos.cabecalho"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
            >
              <template v-slot:item.operacoes="{ item }">
                  <v-icon size="16" color="green" class="operacoes" @click="abrirModalEditarTransp(item)">fas fa-pen</v-icon>
                  <v-icon size="16" color="red" class="operacoes" @click="deleteItem(item)">fas fa-trash</v-icon>
              </template>

              <template v-slot:item.ignorarIntegracao="{ item }">
                <v-app-bar-nav-icon>
                  <v-icon size="16" color="green" v-if="item.ignorarIntegracao == false">fas fa-check</v-icon>
                  <v-icon size="16" color="red" v-else>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

    <!-- Inicio Modal Add Token -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogAddTransportadora" max-width="900">
          <v-card>
            <v-container>
              <v-card-title class="text-h5" style="justify-content:center"> Adicionar - Transportadora </v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-card-title class="text-h7"> Código Sisfrete </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="text" :rules="[() => itemSelecionado.codigoSisfrete.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.codigoSisfrete"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Código Transportadora </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="text" v-model="itemSelecionado.codigo"></v-text-field>
                  </v-card-text>
                </v-col>

                <v-col cols="6" sm="8" md="8">
                  <v-card-title class="text-h7" style="padding-top: 0"> Nome Transportadora </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo :rules="[() => itemSelecionado.nome.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.nome"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Base </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="text" :rules="[() => itemSelecionado.base.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.base"></v-text-field>
                  </v-card-text>
                </v-col>

                <v-col cols="6" sm="8" md="8">
                  <v-card-title class="text-h7" style="padding-top: 0"> Serviço Entrega </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo :rules="[() => itemSelecionado.servicoEntrega.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.servicoEntrega"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Código Serviço Transp. </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="text" v-model="itemSelecionado.codigoServico"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px">
                <v-col cols="6" sm="8" md="4">
                  <v-checkbox
                  v-model="itemSelecionado.ignorarIntegracao"
                  label="Ignorar integração"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions class="alignmentDialog">
              <v-btn class="ma-2" color="error" @click="dialogAddTransportadora = false">
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="addTransp()"
              >
                Salvar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Add Token -->

      <!-- Modal Alteracao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogEditTransportadora" max-width="900">
          <v-card>
            <v-container>
              <v-card-title class="text-h5" style="justify-content:center"> Editar - Transportadora </v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-card-title class="text-h7"> Código Sisfrete </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="number" :rules="[() => itemSelecionado.codigoSisfrete.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.codigoSisfrete"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Código Transportadora </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="number" v-model="itemSelecionado.codigo"></v-text-field>
                  </v-card-text>
                </v-col>
                <v-col cols="6" sm="8" md="8">
                  <v-card-title class="text-h7" style="padding-top: 0"> Nome Transportadora </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo :rules="[() => itemSelecionado.nome.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.nome"></v-text-field>
                  </v-card-text>
                </v-col>


              </v-row>

              <v-row>
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Base </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="number" :rules="[() => itemSelecionado.base.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.base"></v-text-field>
                  </v-card-text>
                </v-col>
                <v-col cols="6" sm="8" md="8">
                  <v-card-title class="text-h7" style="padding-top: 0"> Serviço Entrega </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo :rules="[() => itemSelecionado.servicoEntrega.length > 0 || 'Campo obrigatório']" v-model="itemSelecionado.servicoEntrega"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="8" md="4">
                  <v-card-title class="text-h7" style="padding-top: 0"> Código Serviço Transp. </v-card-title>
                  <v-card-text style="padding-bottom: 0">
                    <v-text-field solo type="number" v-model="itemSelecionado.codigoServico"></v-text-field>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row style="padding-left: 16px">
                <v-col cols="6" sm="8" md="4">
                  <v-checkbox
                  v-model="itemSelecionado.ignorarIntegracao"
                  label="Ignorar integração"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions class="alignmentDialog">
              <v-btn class="ma-2" color="error" @click="dialogEditTransportadora = false">
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="editTransp()"
              >
                Salvar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Alteracao -->

      <!-- Inicio Modal Exclusao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h9 alignmentDialogDelete">
              Warning
            </v-card-title>
            <v-card-text class="alignmentDialogDelete">
              <strong>Deseja realmente excluir a Transportadora?<br>
              Código Transportadora Sisfrete: {{ itemSelecionado.codigoSisfrete }}<br>
              Nome: {{ itemSelecionado.nome }}<br>
              Código Transportadora Cliente: {{ itemSelecionado.codigo }}</strong></v-card-text
            >
            <v-card-actions class="alignmentDialog">
              <v-btn class="ma-2" color="primary" @click="dialogDelete = false">
                Cancelar
              </v-btn>
              <v-btn class="ma-2" color="error" @click="deleteTransp()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Exclusao -->

      <!-- Inicio Modal Sucesso ao excluir -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              ><strong>Transportadora excluída com sucesso!!!</strong></v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="dialogSucessDelete = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Sucesso ao excluir -->

      <!-- Inicio modal Error salvar informacoes-->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogError" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Error
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Erro ao salvar as informações. Tente novamente. Se o erro persistir, contacte um administrador.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="error" @click="dialogError = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Error salvar informacoes-->

       <!-- Inicio modal Sucesso ao editar -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessSave" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Configurações de Transporadora salva.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="dialogSucessSave = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Sucesso ao editar-->
  </div>
</template>

<script>
import { baseApiUrlPersys } from "@/global";
import axios from "axios";
import Template from "../components/Template.vue";


export default {
  components: {
    Template,
  },
  name: "Objetos",
  data: () => ({
    checkboxIntegracao: false,
    dialogSucessDelete: false,
    dialogAddTransportadora: false,
    dialogEditTransportadora: false,
    dialogDelete: false,
    dialogSucessSave: false,
    dialogError: false,
    cod_empresa: '',
    itemSelecionado: {
      codigoSisfrete: "",
      codigo: "",
      codigoServico: "",
      nome: "",
      servicoEntrega: "",
      base: "",
      identificador: "",
      ignorarIntegracao: "",
    },

    grid: {
      objetos: {
        cabecalho: [
          { text: "Código Transportadora Sisfrete", value: "codigoSisfrete", sortable: false },
          { text: "Código Transportadora Cliente", value: "codigo", sortable: false },
          { text: "Nome Transportadora", value: "nome", sortable: false },
          { text: "Serviço Entrega", value: "servicoEntrega", sortable: false },
          { text: "Base", value: "base", sortable: false },
          { text: "Código Serviço Transportadora", value: "codigoServico", sortable: false },
          { text: "Integração", value: "ignorarIntegracao", sortable: false },
          { text: "", value: "operacoes", sortable: false, align: "end" },
        ],
        items: [],
        carregando: false,
        porPagina: 10,
      },
    },
  }),
  created() {
    this.cod_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarTransportadoras();
  },
  methods: {

    async carregarTransportadoras() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      await axios
        .get(`${baseApiUrlPersys}/transportadora/empresa`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.data == ''){
            this.grid.objetos.items = [];
            this.grid.objetos.porPagina = 1000;
          }else{
            this.grid.objetos.items = res.data;
            this.grid.objetos.porPagina = 1000;
          }
          this.grid.objetos.carregando = false
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.grid.objetos.carregando = false
        })
    },

    async addTransp() {
      if (this.itemSelecionado.base.length == 0 && this.itemSelecionado.codigoSisfrete.length == 0 && this.itemSelecionado.nome.length == 0 && this.itemSelecionado.servicoEntrega.length == 0){
        alert("O campos são obrigatórios")
        return
      }else{
        await axios
        .post(`${baseApiUrlPersys}/transportadora`, {
          codigoSisfrete: this.itemSelecionado.codigoSisfrete,
          codigo: this.itemSelecionado.codigo,
          codigoServico: this.itemSelecionado.codigoServico,
          base: this.itemSelecionado.base,
          nome: this.itemSelecionado.nome.toUpperCase(),
          servicoEntrega: this.itemSelecionado.servicoEntrega.toUpperCase(),
          codigoEmpresa: this.cod_empresa,
          ignorarIntegracao: this.itemSelecionado.ignorarIntegracao,
        })
        .then((response) => {
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Transportadora adicionada com sucesso.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.dialogAddTransportadora = false;
          this.limparItemSelecionado();
          this.carregarTransportadoras();
        })
        .catch((error) => {
          this.dialogError = true;
        })
      }
    },

    async editTransp() {
      if (this.itemSelecionado.base.length == 0 && this.itemSelecionado.codigoSisfrete.length == 0 && this.itemSelecionado.nome.length == 0 && this.itemSelecionado.servicoEntrega.length == 0){
        alert("O campos são obrigatórios")
        return
      }else{
        await axios
        .put(`${baseApiUrlPersys}/transportadora/${this.itemSelecionado.identificador}`, {
          codigoSisfrete: this.itemSelecionado.codigoSisfrete,
          codigo: this.itemSelecionado.codigo,
          nome: this.itemSelecionado.nome.toUpperCase(),
          servicoEntrega: this.itemSelecionado.servicoEntrega.toUpperCase(),
          codigoEmpresa: this.cod_empresa,
          base: this.itemSelecionado.base,
          codigoServico: this.itemSelecionado.codigoServico,
          ignorarIntegracao: this.itemSelecionado.ignorarIntegracao,

        })
        .then((response) => {
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Transportadora editada com sucesso.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.dialogEditTransportadora = false;
          this.limparItemSelecionado();
          this.carregarTransportadoras()
        })
        .catch((error) => {
          console.log(error);
          this.dialogError = true;
        })
      }
    },

    async deleteItem (item) {
      this.$swal({
        title: 'Confirmação',
        html: `Deseja realmente excluir a Transportadora?<br> Código Transportadora Sisfrete: ${ item.codigoSisfrete }<br> Nome: ${ item.nome }<br> Código Transportadora Cliente: ${ item.codigo } `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseApiUrlPersys}/transportadora/${item.identificador}`)
            .then((res) => {
              this.$swal({
              title: 'Sucesso!!!',
              text: 'Transportadora excluída com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
              this.carregarTransportadoras();
            });
        }
      });
    },

    limparItemSelecionado(){
      this.itemSelecionado.codigoSisfrete = "";
      this.itemSelecionado.codigo = "";
      this.itemSelecionado.codigoServico = "";
      this.itemSelecionado.nome = "";
      this.itemSelecionado.servicoEntrega = "";
      this.itemSelecionado.identificador = "";
      this.itemSelecionado.base = "";
      this.itemSelecionado.ignorarIntegracao = ""
    },

    abrirModalEditarTransp(item) {
      this.dialogEditTransportadora = true;
      this.itemSelecionado = item
    },

    abrirModalApagarTransp(item){
      this.dialogDelete = true;
      this.itemSelecionado = item
    },
  },
};
</script>
<style scoped>
.operacoes {
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 8px;
}
.operacoes::before{
  background-color: transparent !important;
}

.operacoes::after{
  background-color: transparent !important;
}
.alignmentDialog, .btnOperacoes {
  display: flex;
  justify-content: flex-end;
}
.alignmentDialogDelete{
  display: flex;
  justify-content: center;
}

.v-icon.v-icon::after{
  display: none;
}

</style>
